<template>
  <div v-if="hasRankBlock" class="mt-5">
    <p class="has-text-weight-bold">
      <u>RANKED LISTS</u>
    </p>
    <table class="table is-narrow is-size-7">
      <thead>
        <tr>
          <th>Rank</th>
          <th>List</th>
          <th>Criteria</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="list in sortedLists" :key="list.key">
          <td>{{ list.rank }}</td>
          <td>{{ list.key }}</td>
          <td>{{ list.field }}</td>
          <td>{{ list.value }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';

export default {
  mixins: [formatField],
  props: {
    record: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/all',
    }),
    lists() {
      return this.record?.lists ?? [];
    },
    sortedLists() {
      return [...this.lists]
        .sort((a, b) => a.rank - b.rank)
        .map((list) => {
          const listField = this.fields.find((field) => field.name === list.field);
          /* eslint-disable no-param-reassign */
          list.value = this.formatField(listField.type, list.value);
          return list;
        });
    },
    hasRankBlock() {
      return this.lists.length > 0;
    },
  },
};
</script>
