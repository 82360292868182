<template>
  <p
    v-if="record[primaryFilter]"
    class="subtitle is-hidden-desktop is-hidden-tablet-only"
  >
    <fa icon="chevron-right" class="fa-fw" />
    {{ primaryFilterField }}
  </p>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    primaryFilter() {
      return this.currentDirectory.primary_filter.field;
    },
    primaryFilterField() {
      if (Array.isArray(this.record[this.primaryFilter])) {
        return this.record[this.primaryFilter][0];
      }

      return this.record[this.primaryFilter];
    },
  },
};
</script>
