<template>
  <div>
    <p v-for="value in record[facet.key]" :key="value">
      <a :href="link(value)">
        {{ value }}
      </a>
    </p>
  </div>
</template>

<script>
export default {
  props: {
    facet: Object,
    record: Object,
  },
  computed: {
    directory() {
      return this.facet.crossdirectory ?? this.$route.params.directory;
    },
  },
  methods: {
    link(value) {
      return `/${this.directory}/?refinementList[${this.facet.key}]=${encodeURIComponent(value)}`;
    },
  },
};
</script>
