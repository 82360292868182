<template>
  <div>
    <div v-for="field in filteredFields" :key="field.key" class="is-field">
      <h2 class="has-text-weight-bold" v-html="field.name.toUpperCase()" />

      <Annualized v-if="field.isAnnual" :record="record" :field="field" />

      <template v-else>
        <TreeFacet v-if="field.type === 'tree'" :record="record" :facet="field" />
        <EmailField v-else-if="field.key === 'email' && record['sponsor']" :field="field" />
        <URLField v-else-if="field.type === 'url'" :field="field" />
        <div
          v-else-if="field.key === 'bio' && field.value.length > 0"
          class="content"
          v-html="field.value"
        />
        <span v-else class="subtitle is-size-6" v-html="field.value" />
      </template>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import formatField from '@/mixins/fields/formatField';
import Annualized from './Field/Annualized.vue';
import EmailField from './Field/Email.vue';
import URLField from './Field/URL.vue';
import TreeFacet from './Facets/TreeFacet.vue';

export default {
  mixins: [formatField],
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      fields: 'fields/get',
    }),
    filteredFields() {
      const filteredNames = ['Name', 'Phone', 'Latitude/longitude', 'Web Profile'];

      return this.fields
        .filter((field) => !filteredNames.includes(field.name))
        .filter((field) => field.free || String(this.record.sponsor).toLowerCase() === 'true')
        .filter((field) => ![undefined, null].includes(this.record[field.key]))
        .filter((field) => (field.type === 'tree' ? this.record[field.key].lvl0.length > 0 : true))
        .map((field) => {
          const recordValue = this.record[field.key];
          const isAnnual = this.isAnnualData(recordValue);

          return {
            isAnnual,
            recordValue,
            value: this.formatField(field.type, recordValue),
            ...field,
          };
        });
    },
  },
  methods: {
    isAnnualData(field) {
      const key = Object.keys(field)[0];
      return key?.length === 4 && /^\d+$/.test(key);
    },
  },
  components: {
    Annualized,
    EmailField,
    URLField,
    TreeFacet,
  },
};
</script>
