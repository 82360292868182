<template>
  <div class="column has-text-right correction-section">
    <a
      target="_blank"
      rel="noopener"
      class="button is-primary"
      :href="correctionsLink"
    >{{ correctionsText }}</a>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    name: {
      type: String,
    },
  },
  computed: {
    ...mapState({
      clientName: (state) => state.name,
      directoryName: (state) => state.route.params.directory,
    }),
    ...mapGetters({
      currentDirectory: 'directory/current',
    }),
    correctionsText() {
      return this.currentDirectory?.corrections_text ?? 'Submit Changes';
    },
    correctionsLink() {
      return this.currentDirectory?.corrections_link ?? `mailto:corrections@datajoe.com?subject=${this.clientName} Updates | ${this.directoryName} | ${this.name}`;
    },
  },
};
</script>
