<template>
  <div
    v-if="isExpertEnabled && expert"
    class="has-background-danger featured-icon has-text-white tag
    has-text-weight-semibold is-marginless is-expert-badge"
  >
    {{ expertBadge }}
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    expert: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      isExpertEnabled: (state) => state.expert?.enabled,
      expertBadge: (state) => state.expert?.badge ?? 'EXPERT',
    }),
  },
};
</script>
