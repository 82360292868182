<template>
  <a :href="link">
    {{ record[facet.key] }}
  </a>
</template>

<script>
export default {
  props: {
    facet: Object,
    record: Object,
  },
  computed: {
    link() {
      return `/${this.directory}/?menu[${this.facet.key}]=${encodeURIComponent(this.record[this.facet.key])}`;
    },
    directory() {
      return this.facet.crossdirectory ?? this.$route.params.directory;
    },
  },
};
</script>
