<template>
  <router-link
    v-if="isExpertEnabled"
    :to="{ name: 'expert' }"
    class="button is-primary"
  >
    {{ expertLabel }}
  </router-link>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      isExpertEnabled: (state) => state.expert?.enabled,
      expertLabel: (state) => state.expert?.button_label ?? 'Become a Preferred Partner',
    }),
  },
};
</script>
